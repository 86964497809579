import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week starts today`}</em></p>
    <p>{`DB Push Press 12-12-12\\@60%1RM`}</p>
    <p>{`&`}</p>
    <p>{`Band Straight Arm Pulldowns 12-12-12`}</p>
    <p>{`then,`}</p>
    <p>{`2 Rounds for time of:`}</p>
    <p>{`800M Run`}</p>
    <p>{`30-DB S2OH (40/25’s)`}</p>
    <p>{`30-Box Jumps (24/20″)`}</p>
    <p><em parentName="p">{`*`}{`compare to 10/9/16`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The sign up sheet for Memorial Day Murph is up next to the front
white board.  We have 15 slots each half hour starting at 10:30am (10:30
& 11:00 slots are filled) on the 28th so sign up ahead of time!  If you
are a visitor and won’t be in before Murph you can email us and we’ll
sign you up.  All other class times and open gym are cancelled for the
day.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      